import React, {useState} from "react";

// const defaultCart = {
//     items: [],
//     cartCount: 0,
//     addToCart: function (product) { },
//     removeFromCart: function (productId) { }
// };
//
// const CartContext = React.createContext(defaultCart);
//
// export default CartContext

export const CartContext = React.createContext({
    productInCart: null,
    addProduct: (newProduct) => {},
    dodatekInCart: null,
    addDodatek: (newDodatek) => {},
    removeDodatek: (newDodatek) => {},
    busyMutation: false,
    setBusyMutation: (state) => {},
    openPricingTable: false,
    setopenPricingTable: (state) => {}
})

export const CartProvider = ({children}) => {
    const [pakiet, setPakiet] = useState(null)
    const [dodatek, setDodatek] = useState([])
    const [busyMutation, setBusyMutation] = useState(false)
    const [openPricingTable, setopenPricingTable] = useState(false)

    const handleMutation = (state) => {
        setBusyMutation(state)
    }

    const openPricingtable = (state) => {
        setopenPricingTable(state)
    }

    const handleSetPakiet = (p) => {
        setPakiet(p)
    }

    const handleSetDodatek = (dodatek) => {
        setDodatek(dodatkiIds => ([
            ...dodatkiIds,
            dodatek
        ]))
    }

    const handleRemoveDodatek = (id) => {
        setDodatek((current) =>
            current.filter(dodatek =>
                dodatek !== id
            )
        )
    }

    const contextValue = {
        productInCart: pakiet,
        addProduct: handleSetPakiet,
        dodatekInCart: dodatek,
        addDodatek: handleSetDodatek,
        removeDodatek: handleRemoveDodatek,
        busyMutation: busyMutation,
        setBusyMutation: handleMutation,
        openPricingTable: openPricingTable,
        setopenPricingTable: openPricingtable
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}